//import modules
import { React } from "react";
import Marquee from "react-fast-marquee";

//import css
import "../css/Partners.css";

import partnerList from "../dataFiles/partners.json";
// {
//   partnerList.map((image, index) => (
//     <img key={index} src={image.img} alt={`${index + 1}`} />
//   ));
// }
function Partners() {
  return (
    <div className="partnersContainer" id="ourPartners">
      <h2>ELGUARDS PARTNERS</h2>
      <Marquee>
        {partnerList.map((item) => (
          <img src={item.img} alt={item.alt} className="partnersImage" />
        ))}
      </Marquee>
    </div>

    // <div className="partnersContainer" id="ourPartners">
    //   <h1>ELGUARDS PARTNERS</h1>

    //   {/* <div
    //     style={{ display: "flex", overflow: "hidden", whiteSpace: "nowrap" }}
    //   >
    // {partnerList.map((image, index) => (
    //   <img
    //     key={index}
    //     style={{
    //       display: "inline-block",
    //       width: "33.33%",
    //       height: "100%",
    //       objectFit: "cover",
    //       transform: `translateX(${(index - 1) * 33.33 - currentOffset}%)`,
    //     }}
    //     src={image.img}
    //     alt={`${index + 1}`}
    //   />
    // ))}
    //   </div> */}
    // </div>
  );
}

export default Partners;
