import React from "react";
import { Link } from "react-scroll";

import LiveCounterBar from "./LiveCounterBar";
import VideoPlayer from "./VideoPlayer";

import "../css/VideoPlayer.css";
import "../css/About.css";

function About() {
  return (
    <div className="aboutContainer">
      <div className="headerVideoPlayerContainer" id="about">
        <VideoPlayer />
        <LiveCounterBar />
      </div>
      <div className="aboutDescription">
        <h2>About ELGUARDS</h2>
        <p>
          ELGUARDS allows organizations to measure and improve cybersecurity
          awareness knowledge of employees and test their information technology
          and security defenses. In fact, personnel are often the weakest link
          in the cybersecurity chain, as the human element is often the primary
          cause of many severe data breaches through simple mistakes such as
          downloading malware or clicking links in phishing emails.
        </p>
        <Link
          to="contactUs"
          spy
          offset={-100}
          style={{
            textDecoration: "none",

            cursor: "pointer",
          }}
        >
          <button className="homepageHeaderButton">Get In Touch</button>
        </Link>
      </div>
    </div>
  );
}

export default About;
