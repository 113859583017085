import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { MDBBtn } from "mdb-react-ui-kit";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsLinkedin,
  BsInstagram,
} from "react-icons/bs";
import { Textfit } from "react-textfit";

import Carousel from "react-bootstrap/Carousel";

import headerItems from "../dataFiles/headerList.json";
import "../css/HeaderCard.css";

function HeaderCard() {
  const [activeIndex, setActiveIndex] = useState(0);

  const interval = setInterval(() => {
    // Function that gets called every 5 seconds
    next();
  }, 5000);

  useEffect(() => {
    return () => clearInterval(interval);
  }, [interval]);

  const next = () => {
    const nextIndex =
      activeIndex === headerItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? headerItems.length - 1 : activeIndex - 1;

    setActiveIndex(nextIndex);
  };

  const TitleText = ({ item }) => {
    const components = item.title.split(/<u>|<\/u>/).map((text, i) => {
      if (i % 2 === 0) return text;
      return (
        <span key={item.id + "span"} className="mainCarsTitleUnderlined">
          {text}
        </span>
      );
    });

    return (
      <div mode="multi" min={200} className="mainCarsTitle">
        {components}
      </div>
    );
  };

  const slides = headerItems.map((item) => {
    return (
      <Carousel.Item key={item.id}>
        <div
          className="bgDiv"
          // style={{
          //   backgroundImage: `url(${item.img})`,
          // }}
          style={{
            backgroundImage: `url(${item.img})`,
          }}
        >
          <div className="socialDiv2">
            <a
              href="https://www.linkedin.com/company/elguards/"
              target="_blank"
            >
              <BsLinkedin size={30} />
            </a>
            <a href="#">
              <BsInstagram size={30} />
            </a>
          </div>
          {/* <div className="socialDiv">
            <a
              href="https://www.linkedin.com/company/elguards/"
              target="_blank"
            >
              LinkedIn
            </a>{" "}
            / <a href="#">Instagram</a>
          </div> */}
          <div className="mainCarouselDiv">
            <h3 className="mainCarouselPreTitle">{item.preTitle}</h3>
            {/* <div>
              <div className="mainCarsTitle ">{item.title}</div>
            </div> */}
            {TitleText({ item })}
            <div className="mainCarsSubtitle ">{item.subtitle}</div>

            <div className="carouselDiv2">
              <h3 className="carouselDiv2Heading">{item.callToActionDesc}</h3>
              {item.callToAction != "" && (
                <Link
                  to="contactUs"
                  spy
                  offset={-100}
                  style={{
                    textDecoration: "none",

                    cursor: "pointer",
                  }}
                >
                  <button className="callToAction">{item.callToAction}</button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </Carousel.Item>
    );
  });

  const [paused, setPaused] = useState(false);
  return (
    <div id="homeHeader" className="carouselContainer">
      <div className="headerBtnsDiv">
        <MDBBtn
          tag="a"
          color="none"
          onClick={previous}
          className="headerBtnLeft"
          aria-label="previous-button"
          key={"previous=button"}
        >
          <BsArrowLeftCircle />
        </MDBBtn>
        <MDBBtn
          tag="a"
          color="none"
          onClick={next}
          className="headerBtnRight"
          aria-label="next-button"
          key={"next-button"}
        >
          <BsArrowRightCircle />
        </MDBBtn>
      </div>
      <div className="scrollAlertContainer">
        <div>Scroll for More</div>
        <div className="scrollAlertImage">
          <div className="scrollAlertAnimDiv"></div>
        </div>
      </div>
      <Carousel
        pause={"hover"}
        indicators={false}
        activeIndex={activeIndex}
        nextLabel={next}
        prevLabel={previous}
        controls={false}
        style={{
          zIndex: 10,
        }}
      >
        {slides}
      </Carousel>
    </div>
  );
}

export default HeaderCard;
