import React, { useEffect, useState } from "react";

import "../css/VideoPlayer.css";

import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";

import video from "../vids/ElGuards_Ad_Short_01.mp4";
import image from "../imgs/vid_poster.png";

function VideoPlayer() {
  const [playing, setIsPlaying] = useState(true);
  const setDefaultVolume = () => {
    this.volume = 0.5;
  };
  const playVideo = () => {
    var video = document.getElementById("mainVideo");
    var playButton = document.getElementById("playButton");
    if (video.paused == true) {
      // Play the video
      video.play();
      playButton.style.opacity = 0;
      setIsPlaying(true);
    } else {
      // Pause the video
      video.pause();
      setIsPlaying(false);
      playButton.style.opacity = 1;

      // Update the button text to 'Play'
    }
  };

  return (
    <div className="videoPlayerContainer">
      <button
        type="button"
        id="playButton"
        aria-label="play"
        className="videoPlayButton"
        onClick={playVideo}
      >
        {playing ? <AiFillPlayCircle /> : <AiFillPauseCircle />}
      </button>
      <video
        className="videoContent"
        autoPlay={true}
        id="mainVideo"
        poster={image}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag
      </video>
    </div>
  );
}

export default VideoPlayer;
