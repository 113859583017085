import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import "../css/Services.css";

{
  /* <div className="arrowContainer">
      <BsArrowLeft size={"2rem"} onClick={() => scrollPrev()} />
    </div>
    <div className="arrowContainer">
      <BsArrowRight size={"2rem"} onClick={() => scrollNext()} />
    </div> */
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    items,
    scrollToItem,
    getItemElementById,
  } = React.useContext(VisibilityContext);

  return (
    <div
      className="arrowContainer"
      onClick={() => {
        if (isFirstItemVisible) {
          scrollToItem(getItemElementById(items.toArr().slice(-1)?.[0]?.[0]));
        } else {
          scrollPrev();
        }
      }}
    >
      <BsArrowLeft size={"2rem"} />
    </div>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    items,
    scrollToItem,
    getItemElementById,
  } = React.useContext(VisibilityContext);

  return (
    <div
      className="arrowContainer"
      disabled={false}
      onClick={() => {
        if (isLastItemVisible) {
          scrollToItem(getItemElementById(items.toArr()?.[0]?.[0]));
        } else {
          scrollNext();
        }
      }}
    >
      <BsArrowRight size={"2rem"} />
    </div>
  );
}
