//import modules
import React from "react";

//import css
import "../css/Footer.css";

import { BsLinkedin, BsInstagram } from "react-icons/bs";

//import imgs
import ElguardsLogo from "../imgs/ELGUARDS_LOGO-02.svg";

function Footer() {
  return (
    <div id="footer" className="footerContainer">
      <div className="footerLeftCol">
        <img alt="ELGUARDS" src={ElguardsLogo} className="footerLogo" />
        <div className="footerTextDiv">all copyrights reserved</div>
      </div>
      <div className="footerRightCol">
        <a
          href="https://www.linkedin.com/company/elguards/"
          target="_blank"
          className="footerTextDiv"
        >
          <BsLinkedin />
        </a>
        <a href="#" className="footerTextDiv">
          <BsInstagram />
        </a>
        {/* <a
          href="https://www.linkedin.com/company/elguards/"
          target="_blank"
          className="footerTextDiv"
        >
          LinkedIn
        </a>{" "}
        /{" "}
        <a href="#" className="footerTextDiv">
          Instagram
        </a> */}
      </div>
    </div>
  );
}

export default Footer;
