import { React } from "react";

import "../css/Approach.css";

function PickColor(color) {
  return color ? "#0550f0" : "#E0E0E0";
}

export function ApproachCard({ title, icon, color, itemId }) {
  return (
    <div
      className="approachCardContainer"
      style={{ backgroundColor: PickColor(color) }}
    >
      <div>{icon}</div>
      <div className="approachCardTitle">
        <h4 style={{ color: PickColor(!color) }}>{title}</h4>
      </div>
    </div>
  );
}
