import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";

export const NavbarData = [
  // {
  //   title: "Home",
  //   id: "spy1",
  //   to: "homeHeader",
  //   cName: "nav-text",
  //   icon: <AiIcons.AiFillHome />,
  //   display: true,
  // },
  {
    title: "About",
    id: "spy2",
    to: "about",
    cName: "nav-text",
    icon: <AiIcons.AiFillDatabase />,
    display: true,
  },
  {
    title: "Our Services",
    id: "spy2",
    to: "services",
    cName: "nav-text",
    icon: <AiIcons.AiFillDatabase />,
    display: true,
  },
  {
    title: "Our Approach",
    id: "spy3",
    to: "approach",
    cName: "nav-text",
    icon: <HiIcons.HiUsers />,
    display: true,
  },
  {
    title: "Our Clients",
    id: "spy4",
    to: "ourClients",
    cName: "nav-text",
    icon: <FaIcons.FaTable />,
    display: true,
  },
  // {
  //   title: "Contact Us",
  //   id: "spy5",
  //   to: "contactUs",
  //   cName: "nav-text",
  //   icon: <FaIcons.FaFileExcel />,
  //   display: true,
  // },
];
