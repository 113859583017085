import { React, useState } from "react";
import Collapsible from "react-collapsible";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";

import "../css/Services.css";

export function ServicesCard({ title, description, icon, itemId }) {
  const [open, setOpen] = useState(false);
  const [arrow, setArrow] = useState(false);

  return (
    <div className="servicesCardContainer" onClick={() => setOpen(!open)}>
      <div>{icon}</div>
      <div className="servicesCardTitle">
        <h3>{title}</h3>
      </div>

      <Collapsible
        open={open}
        onOpen={() => setArrow(true)}
        onClose={() => setArrow(false)}
      >
        <div
          className="servicesCardDescription"
          style={{ whiteSpace: "pre-line" }}
        >
          {description}
        </div>
      </Collapsible>
      <div className="bottomArrowContainer">
        {!arrow && (
          <BsArrowDownCircle
            color="#3E4DA1"
            className="bottomArrow"
            // onClick={() => setOpen(!open)}
          />
        )}
        {arrow && (
          <BsArrowUpCircle
            color="#3E4DA1"
            className="bottomArrow"
            // onClick={() => setOpen(!open)}
          />
        )}
      </div>
    </div>
  );
}
