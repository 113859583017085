//import modules
import React from "react";
import { HiOutlineGlobeEuropeAfrica, HiArrowLongRight } from "react-icons/hi2";

//import css
import "../css/GetInTouch.css";

function GetInTouch() {
  return (
    <div className="getinTouchContainer" id="getInTouch">
      <div className="getinTouchLeftCol">
        <h5>Get in touch</h5>
        <h2>Getting secured is one phone call away!</h2>
      </div>
      <div className="getinTouchRightCol">
        <div className="getinTouchRightColGlobe">
          <HiOutlineGlobeEuropeAfrica />
        </div>
        <a
          className="getinTouchRightColText"
          href="https://goo.gl/maps/2cfpK3QzvcxG81Zr8"
          target="_blank"
        >
          <div>Our Offices</div>
          <div>
            <b>Dubai, UAE</b>
          </div>
          <HiArrowLongRight className="getinTouchRightColIcon" />
        </a>
      </div>
    </div>
  );
}

export default GetInTouch;
