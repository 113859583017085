//import modules
import { React, useState, useEffect, useRef } from "react";
import Marquee from "react-fast-marquee";

//import css
import "../css/Clients.css";

import clientList from "../dataFiles/clients.json";

function Clients() {
  return (
    <div className="clientsContainer" id="ourClients">
      <h2>ELGUARDS CLIENTS</h2>
      <Marquee speed={25}>
        {clientList.map((item) => (
          <img src={item.img} alt={item.alt} className="clientImage" />
        ))}
      </Marquee>
    </div>
  );
}

export default Clients;
