//import modules
import { React } from "react";

//import components
import HeaderCard from "../components/HeaderCard";
import ShowcaseWithList from "../components/ShowcaseWithList";
import Services from "../components/Services";
import Approach from "../components/Approach";
import Showcase from "../components/Showcase";
import Partners from "../components/Partners";
import Clients from "../components/Clients";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import GetInTouch from "../components/GetInTouch";
import About from "../components/About";
import VideoPlayer from "../components/VideoPlayer";

function Home() {
  const setDefaultVolume = () => {
    this.volume = 0.5;
  };

  return (
    <div className="homepageContainer">
      <HeaderCard id="header" />

      <About />

      <div className="homeMainContentContainer">
        <hr className="servicesHr" />
        <Services />
        {/* <ShowcaseWithList /> */}
        <hr />
        <Approach />
        {/* <Showcase /> */}
        <hr />
        <Clients />
        <Partners />
        <hr />
        <ContactForm />
        <Footer />
        <hr />
      </div>
    </div>
  );
}

export default Home;
