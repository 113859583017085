import React from "react";
import { MdMenuBook, MdEngineering } from "react-icons/md";
import { GiFishingHook } from "react-icons/gi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { TbDeviceAnalytics } from "react-icons/tb";

const ICON_SIZE = "8rem";

export const ServicesData = [
  {
    title: "Learning Management System - LMS",
    id: 1,
    description:
      "o Multilingual Computer Based Training (CBT) and Gamification\no Quizzes\no Newsletters and Threat Update\no SaaS or On-Prem",
    icon: <MdMenuBook size={ICON_SIZE} color="#0550f0" />,
  },
  {
    title: "Phishing Simulation",
    id: 2,
    description: "o +100 Phishing Templates\no Unlimited Phishing Campaigns",
    icon: <GiFishingHook size={ICON_SIZE} color="#0550f0" />,
  },
  {
    title: "iLT / ViLT Training",
    id: 3,
    description:
      "o Live Cybersecurity Awareness Session\no Training Content Creation",
    icon: <FaChalkboardTeacher size={ICON_SIZE} color="#0550f0" />,
  },
  {
    title: "Social Engineering Attacks",
    id: 4,
    description:
      "o ELGUARDS enables you to simulate the full threat landscape that goes beyond just simple phishing emails like (Tailgating, Shoulder surfing, Ransomware simulation, USB dropping...etc.)",
    icon: <MdEngineering size={ICON_SIZE} color="#0550f0" />,
  },
  {
    title: "Skills Gap Analysis",
    id: 5,
    description:
      "o IS/IT Technical Team Assessment\no Official Training and Certification\no Tailored Courses and Trainings",
    icon: <TbDeviceAnalytics size={ICON_SIZE} color="#0550f0" />,
  },
];
