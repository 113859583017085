//import modules
import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { MDBBtn } from "mdb-react-ui-kit";

import "../css/Navbar.css";

import { CgSearch } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";

//import imgs
import ElguardsLogo from "../imgs/ELGUARDS_LOGO-02.svg";
import { NavbarData } from "../components/NavbarData";

function Navbar() {
  const [hamburger, setHamburger] = useState(false);
  useEffect(() => {
    showHamburger();
  }, [hamburger]);
  function showHamburger() {
    //setHamburger(!hamburger);
    // var x = document.getElementById("navBarTop");
    {
      // if (x.className === "navbar-flex-container") {
      //   x.className += " navbar-responsive";
      // } else {
      //   x.className = "navbar-flex-container";
      // }
    }
    var windowInnerWidth = `${window.innerWidth}px`;
    if (hamburger) {
      document.getElementById("navPopup").style.marginLeft = "0px";
      return;
    }
    document.getElementById("navPopup").style.marginLeft = windowInnerWidth;
  }

  // window.addEventListener("orientationchange", function (e) {
  //   if (hamburger) {
  //     setHamburger(false);
  //     return;
  //   }
  //   this.setTimeout(() => {
  //     showHamburger();
  //   }, 300);
  // });

  return (
    <div>
      <div className="navBar-container">
        <div className="navbar-flex-container" id="navBarTop">
          <div className="navbar-brand">
            <Link to="homeHeader" spy offset={-100} key={"homeHeader"}>
              <img
                alt="ELGUARDS"
                src={ElguardsLogo}
                style={{
                  cursor: "pointer",
                  height: "2rem",
                }}
              />
            </Link>
          </div>
          <div
            className="nav-items-container"
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "space-between",
            }}
          >
            <div className="nav-items">
              {NavbarData.map((item, index) => {
                if (item.display == true) {
                  return (
                    <div id="navItem" key={index}>
                      <Link
                        to={item.to}
                        spy
                        offset={-100}
                        className={item.cName}
                        activeClass="nav-text active"
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        {item.title}
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
            <div>
              <Link
                to="contactUs"
                spy
                offset={-100}
                style={{
                  textDecoration: "none",

                  cursor: "pointer",
                }}
              >
                <button className="contact-button-in-nav">Contact Us</button>
              </Link>
            </div>
            <div className="hamburger-icon">
              <MDBBtn
                color="link"
                style={{
                  color: "#0550F0",
                  width: "110px",
                  justifyContent: "center",
                }}
                onClick={() => setHamburger(!hamburger)}
                className="d-flex"
              >
                <GiHamburgerMenu />
              </MDBBtn>
              {/* <GiHamburgerMenu /> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="nav-popup"
        // style={{ marginLeft: `${window.innerWidth}px` }}
        id="navPopup"
      >
        <div className="nav-popup-all-items" id="navPopupItems">
          {NavbarData.map((item, index) => {
            if (item.display == true) {
              return (
                <div id="nav-pop-item" key={index}>
                  <Link
                    onClick={() => setHamburger(false)}
                    to={item.to}
                    spy
                    offset={-100}
                    className="nav-popup-text"
                    activeClass="nav-popup-text active"
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    {item.title}
                  </Link>
                </div>
              );
            }
          })}
        </div>

        <div className="contact-popup-container">
          <Link
            to="contactUs"
            onClick={() => setHamburger(false)}
            spy
            offset={-100}
            style={{
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <button className="contact-button-in-popup">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
