import { useState, useEffect } from "react";
import useInterval from "./useInterval";
import CountUp from "react-countup";

import "../css/LiveCounterBar.css";

function LiveCounterBar() {
  // const [starts, setStarts] = useState([0, 0, 0, 0]);
  // const [ends, setEnds] = useState([690, 790, 15, 123056]);

  const [start1, setStart1] = useState(0);
  const [end1, setEnd1] = useState(690);

  const [start2, setStart2] = useState(0);
  const [end2, setEnd2] = useState(790);

  const [start3, setStart3] = useState(0);
  const [end3, setEnd3] = useState(15);

  const [start4, setStart4] = useState(0);
  const [end4, setEnd4] = useState(123056);

  const interval1 = Math.random() * (30000 - 20000) + 20000;
  const interval2 = Math.random() * (20000 - 10000) + 10000;
  const interval3 = Math.random() * (200000 - 100000) + 100000;
  const interval4 = Math.random() * (1000 - 500) + 500;

  // useInterval(() => {
  //   var newEnd1 = Math.random() * (10 - 1) + 1;
  //   setStart1(end1);
  //   setEnd1(end1 + newEnd1);
  // }, interval1);

  // useInterval(() => {
  //   const newEnd2 = Math.random() * (10 - 1) + 1;
  //   setStart2(end2);
  //   setEnd2(end2 + newEnd2);
  // }, interval2);

  // useInterval(() => {
  //   const newEnd3 = Math.random() * (10 - 1) + 1;
  //   setStart3(end3);
  //   setEnd3(end3 + newEnd3);
  // }, interval3);

  // useInterval(() => {
  //   const newEnd4 = Math.random() * (10 - 1) + 1;
  //   setStart4(end4);
  //   setEnd4(end4 + newEnd4);
  // }, interval4);
  return (
    <div className="liveCounterBarContainer">
      <div className="liveCounterBarCount">
        <h2>
          <CountUp start={start1} end={end1} />
        </h2>
        <div className="liveCounterBarLabel">Companies secured</div>
      </div>
      <div className="vl" />
      <div className="liveCounterBarCount">
        <h2>
          <CountUp start={start2} end={end2} />
        </h2>
        <div className="liveCounterBarLabel">No. of sessions</div>
      </div>
      <div className="vl" />
      <div className="liveCounterBarCount">
        <h2>
          <CountUp start={start3} end={end3} />
        </h2>
        <div className="liveCounterBarLabel">Countries covered</div>
      </div>
      <div className="vl" />
      <div className="liveCounterBarCount">
        <h2>
          <CountUp start={start4} end={end4} />
        </h2>
        <div className="liveCounterBarLabel">No. of phishing attacks</div>
      </div>
    </div>
  );
}
export default LiveCounterBar;
