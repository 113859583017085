import logo from "./imgs/ELGUARDS_LOGO-02.svg";
import "./App.css";

import Home from "./pages/Home";
import Navbar from "./pages/Navbar";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
    </div>
  );
}

export default App;
