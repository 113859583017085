//import modules
import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { MDBBtn } from "mdb-react-ui-kit";
// import { GiHamburgerMenu } from "react-icons/gi";

//import css
import "../css/Showcase.css";
import "../css/Showcasewithlist.css";

import approachItems from "../dataFiles/approachList.json";

function Showcase() {
  const [activeIndex, setActiveIndex] = useState(0);
  const interval = setInterval(() => {
    // Function that gets called every 5 seconds
    next();
  }, 10000);

  useEffect(() => {
    return () => clearInterval(interval);
  }, [interval]);

  const next = () => {
    const nextIndex =
      activeIndex === approachItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? approachItems.length - 1 : activeIndex - 1;

    setActiveIndex(nextIndex);
  };

  const slides = approachItems.map((item) => {
    return (
      <Carousel.Item key={item.id}>
        <div className="showcase-item">
          <img src={item.img} className="showcase-item-img" alt="eye img" />
          <div className="showcase-item-header">
            <h2 className="showcase-item-number">{item.number}</h2>
            <h2 className="showcase-item-heading-top">{item.heading}</h2>
          </div>
          <div className="showcase-text-div">
            {item.isPara ? (
              <p className="showcase-paragraph">{item.content}</p>
            ) : (
              <div className="showcase-grid">
                {item.content.map((content, index) => {
                  const [text, value] = content.split(/\n/);
                  return (
                    <div key={index}>
                      <strong>{text}</strong>
                      <br></br>
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Carousel.Item>
    );
  });

  return (
    <div className="showcaseContainer" id="approach">
      <h1>OUR APPROACH</h1>
      <div className="showcase-arrows-div">
        <MDBBtn
          tag="a"
          color="none"
          onClick={previous}
          className="showcase-arrow-left"
          aria-label="previous-button"
        >
          <AiOutlineArrowLeft size={"2rem"} />
        </MDBBtn>
        <MDBBtn
          tag="a"
          color="none"
          onClick={next}
          className="showcase-arrow-right"
          aria-label="next-button"
        >
          <AiOutlineArrowRight size={"2rem"} />
        </MDBBtn>
      </div>
      <Carousel
        controls={false}
        indicators={false}
        //interval={false}
        //activeIndex={activeIndex}
        activeIndex={activeIndex}
      >
        {slides}
      </Carousel>
    </div>
  );
}

export default Showcase;
