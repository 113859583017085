//import modules
import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import { ServicesCard } from "./ServicesCard";
import { ServicesData } from "./ServicesData";
import { LeftArrow, RightArrow } from "./Arrows";

import "../css/Services.css";
import "../css/hideScrollbar.css";

function Services() {
  return (
    <div className="servicesContainer" id="services">
      <h1>OUR SERVICES</h1>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {ServicesData.map(({ title, id, description, icon }) => (
          <ServicesCard
            title={title}
            description={description}
            icon={icon}
            itemId={id} // NOTE: itemId is required for track items
            key={id}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}

export default Services;
