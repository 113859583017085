import React from "react";
import { MdMenuBook, MdEngineering } from "react-icons/md";
import { GiFishingHook } from "react-icons/gi";
import { FaChalkboardTeacher } from "react-icons/fa";

import { HiOutlineUserGroup } from "react-icons/hi";
import { BiBrain, BiDonateHeart } from "react-icons/bi";
import { VscMegaphone } from "react-icons/vsc";
import { TbChecklist } from "react-icons/tb";
import { BsGearWideConnected } from "react-icons/bs";

export const ApproachData = [
  {
    title: "Change employee's behavior",
    id: "appr1",
    icon: <HiOutlineUserGroup size="5em" color="#0550f0" />,
    color: false,
  },
  {
    title:
      "Develop a suitable program for a company size covering entire company",
    id: "appr2",
    icon: <BiBrain size="5em" color="#E0E0E0" />,
    color: true,
  },
  {
    title:
      "Measure effectiveness of the cybersecurity awareness campaign and responses",
    id: "appr3",
    icon: <VscMegaphone size="5em" color="#0550f0" />,
    color: false,
  },
  {
    title:
      "Coordinated long term campaign which is continuously managed across its lifecycle",
    id: "appr4",
    icon: <TbChecklist size="5em" color="#E0E0E0" />,
    color: true,
  },
  {
    title:
      "Comply with state and local information security laws and policies as they develop and be prepared for audits from shareholders and authorities",
    id: "appr5",
    icon: <BiDonateHeart size="5em" color="#0550f0" />,
    color: false,
  },
  {
    title:
      "Support enterprise requirements for the management of security risks and Inspire employees to make security decisions for themselves and the organization align with enterprise security performance objectives and expectations",
    id: "appr6",
    icon: <BsGearWideConnected size="5em" color="#E0E0E0" />,
    color: true,
  },
];
