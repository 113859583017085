//import modules
import { React } from "react";
import * as HiIcons from "react-icons/hi";
import * as HiIcons2 from "react-icons/hi2";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import env from "react-dotenv";
import { Select, CaretIcon } from "react-responsive-select"; // 'react-responsive-select'

//import css
import "../css/ContactForm.css";
// import "@coreui/coreui/dist/css/coreui.min.css";

// for default styles...
// import "react-responsive-select/dist/react-responsive-select.css";

import { countryList } from "../dataFiles/country.ts";

import footerImg from "../imgs/img-footer.png";
import { useState } from "react";
import { useEffect } from "react";

emailjs.init(env.PUBLIC_KEY);

const handleOnSubmit = async (e) => {
  const serviceId = env.SERVICE_ID;
  const templateId = env.TEMPLATE_ID;

  e.preventDefault();
  await emailjs.sendForm(serviceId, templateId, e.target).then(
    (result) => {
      Swal.fire({
        icon: "success",
        title: "Message Sent Successfully",
        confirmButtonColor: "var(--primary)",
      });
    },
    (error) => {
      Swal.fire({
        icon: "error",
        title: "Oops, something went wrong!",
        text: "Please try again later.\nIf problem persists, please contact the system administrator.",
        confirmButtonColor: "var(--primary)",
      });
    }
  );
  e.target.reset();
};

function ContactForm() {
  const [country, setCountry] = useState(null);
  return (
    <div id="contactUs">
      <h1>CONTACT US</h1>
      <div className="contactMainContainer">
        <div className="contactImgContainer">
          <img src={footerImg} alt="" height="700px" />
        </div>
        <form className="contactFormContainer" onSubmit={handleOnSubmit}>
          <div className="contactFormDiv">
            <div className="contactFormInputContainer" style={{ zIndex: 7 }}>
              <HiIcons.HiOutlineUser className="contactFormInputImg" />
              <input
                type={"text"}
                placeholder="Your Name"
                name="name"
                required
              />
            </div>
            <div className="contactFormInputContainer" style={{ zIndex: 8 }}>
              <HiIcons.HiOutlineOfficeBuilding className="contactFormInputImg" />
              <input
                type={"text"}
                placeholder="Company Name"
                name="company"
                required
              />
            </div>
          </div>
          <div className="contactFormDiv">
            <div className="contactFormInputContainer" style={{ zIndex: 9 }}>
              <HiIcons.HiOutlineMail className="contactFormInputImg" />
              <input
                type={"email"}
                placeholder="Your Email"
                name="email"
                required
              />
            </div>
            <div className="contactFormInputContainer" style={{ zIndex: 10 }}>
              <HiIcons.HiOutlinePhone className="contactFormInputImg" />
              <input
                type={"number"}
                placeholder="Phone Number"
                name="number"
                required
              />
            </div>
            <div className="contactFormInputContainer" style={{ zIndex: 12 }}>
              <HiIcons2.HiOutlineGlobeEuropeAfrica className="contactFormInputImg" />
              <Select
                className="contactFormSelect"
                noSelectionLabel="Select Country"
                name="country"
                options={countryList}
                caretIcon={<CaretIcon />}
                selectedValue=""
                onChange={(newValue) => console.log("onChange", newValue)}
                onSubmit={() => console.log("onSubmit")}
              />
            </div>
          </div>
          <div className="contactFormDiv">
            <div
              className="contactFormInputContainerLarge"
              style={{ zIndex: 11 }}
            >
              <HiIcons.HiOutlineChat className="contactFormInputImg" />
              <textarea placeholder="Your Message" name="message" required />
            </div>
          </div>
          <div
            style={{
              alignSelf: "flex-start",
              marginLeft: ".7rem",
            }}
          >
            <div className="contactFormCheckbox">
              <div>
                <input type={"checkbox"} name="isReceiveEmails" /> Yes, I would
                like to receive news and offers from ELGUARDS via email.
              </div>
              <div>
                <input type={"checkbox"} name="isReceiveCalls" /> Yes, I would
                like to receive phone calls from ELGUARDS.
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="contactFormSubmitButton"
            value="Send"
          >
            Send Now!
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
