//import modules
import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { MDBBtn } from "mdb-react-ui-kit";

//import css
import "../css/Showcasewithlist.css";
import serviceItems from "../dataFiles/servicesList.json";

function ShowcaseWithList() {
  const [activeIndex, setActiveIndex] = useState(0);

  const interval = setInterval(() => {
    // Function that gets called every 5 seconds
    next();
  }, 5000);

  useEffect(() => {
    return () => clearInterval(interval);
  }, [interval]);

  const next = () => {
    const nextIndex =
      activeIndex === serviceItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? serviceItems.length - 1 : activeIndex - 1;

    setActiveIndex(nextIndex);
  };

  const slides = serviceItems.map((item) => {
    return (
      <Carousel.Item key={item.id} interval={100}>
        <div className="showcaseItemContainer">
          <div className="showcaseItemHeader">
            <h2 className="showcaseItemNumber">{item.number}</h2>
            <h2 className="showcaseItemHeadingTop">{item.heading}</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="showcaseItemRight">
              <img src={item.img} alt={item.imgAlt} />
            </div>
            <div className="showcaseItemLeft">
              <ul>
                {item.bulletPoints.map((points) =>
                  Object.keys(points).map((key) => (
                    <li key={key}>{points[key]}</li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </Carousel.Item>
    );
  });

  return (
    <div className="showcaseMainContainer" id="services">
      <h1>SERVICES</h1>
      <div className="showcaseBtnsContainer">
        <MDBBtn
          tag="a"
          color="none"
          onClick={previous}
          className="showcaseBtns"
          aria-label="previous-button"
        >
          <AiOutlineArrowLeft size={"2rem"} />
        </MDBBtn>
        <MDBBtn
          tag="a"
          color="none"
          onClick={next}
          className="showcaseBtns"
          style={{ marginLeft: "10%" }}
          aria-label="next-button"
        >
          <AiOutlineArrowRight size={"2rem"} />
        </MDBBtn>
      </div>
      <Carousel
        controls={false}
        indicators={false}
        activeIndex={activeIndex}
        interval={1000}
        pause={false}
        nextLabel={next}
        prevLabel={previous}
        className="arrow-styles"
      >
        {slides}
      </Carousel>
    </div>
  );
}

export default ShowcaseWithList;
