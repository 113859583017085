//import modules
import React from "react";
import { ApproachCard } from "./ApproachCard";
import { ApproachData } from "./ApproachData";

import "../css/Approach.css";

function Services() {
  return (
    <div className="approachContainer" id="approach">
      <h1>OUR APPROACH</h1>
      <div className="approachCardsContainer">
        {ApproachData.map(({ title, id, icon, color }) => (
          <ApproachCard
            title={title}
            icon={icon}
            color={color}
            itemId={id} // NOTE: itemId is required for track items
            key={id}
          />
        ))}
      </div>
    </div>
  );
}

export default Services;
